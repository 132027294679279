import React, { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useWindowWidth } from '@react-hook/window-size'
import { useTranslation } from 'react-i18next'
import { Input as UIKitInput, Link as UIKitLink } from '@daisy-inc/contest-uikit'
import { updateUserFx } from 'models/user'
import { User } from 'models/user/types'
import { MAX_MOBILE_SCREEN_WIDTH } from 'constants/common'
import auth from 'lib/auth'

const Container = styled.div`
  position: fixed;
  top: 36px;
  right: 36px;
  width: 320px;
  padding: 56px 48px 52px 48px;
  box-sizing: border-box;
  border-radius: 32px 0 32px 32px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 2px 8px rgba(29, 11, 78, 0.08), 0px 8px 24px rgba(29, 11, 78, 0.1);

  @media screen and (max-width: ${MAX_MOBILE_SCREEN_WIDTH}px) {
    position: static;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
  }
`
const Form = styled.form`
  margin-bottom: 54px;
`

const Input = styled(UIKitInput)`
  width: 100%;
  & + & {
    margin-top: 24px;
  }
`

const Link = styled(UIKitLink)`
  & + & {
    margin-top: 16px;
  }
`

export interface ProfileProps {
  user: User
}

export const Profile = React.forwardRef<HTMLDivElement, ProfileProps>(function Profile(props, ref) {
  const { user } = props
  const {
    register,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      username: user.username,
    },
  })

  const handleClickLogout = useCallback(() => {
    auth.logout()
  }, [])
  const updateUser = useCallback(() => {
    if (!isDirty) return
    updateUserFx(getValues())
  }, [isDirty])
  // Сохраняем изменения при блюре какого-либо инпута
  const handleBlur = useCallback(() => {
    updateUser()
    reset(getValues())
  }, [isDirty])
  // Сохраняем изменения при анмаунте (скрытии) профиля
  useEffect(() => updateUser, [])
  const { t } = useTranslation()

  const content = (
    <Container ref={ref}>
      <Form>
        <Input {...register('username')} onBlur={handleBlur} />
        <Input label={t('email')} name='email' value={user.email} readOnly />
      </Form>
      <Link href='' onClick={handleClickLogout}>
        {t('logout')}
      </Link>
    </Container>
  )

  const windowWidth = useWindowWidth({ wait: 0 })

  if (windowWidth <= MAX_MOBILE_SCREEN_WIDTH) {
    // Рендерится в другом корневом элементе, чтобы панель была перед всей страницей
    return ReactDOM.createPortal(content, document.getElementById('modal-root') as Element)
  }

  return content
})
