import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// @ts-expect-error
import resBundle from 'i18next-resource-store-loader!./' // eslint-disable-line
import { LANGUAGES } from './config'

i18n.use(initReactI18next).init({
  lng: LANGUAGES.RUSSIAN,
  resources: resBundle,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n
