import React, { useCallback, useRef, Fragment } from 'react'
import { useStore } from 'effector-react'
import { Flex } from '@chakra-ui/layout'
import { useDisclosure } from '@chakra-ui/hooks'
import { useOnClickOutside } from '@daisy-inc/contest-common/hooks'
import { Profile } from 'components/Profile'
import { $user } from 'models/user'
import { Authentication } from 'components/Authentication'
import { Avatar } from 'components/Avatar'

export interface UserProps {
  onCloseProfile?: () => void
  onOpenProfile?: () => void
}

export function User(props: UserProps) {
  const { onCloseProfile, onOpenProfile } = props
  const user = useStore($user)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const handleAuthentication = useCallback(() => {
    window.location.reload()
  }, [])

  const profileRef = useRef<HTMLDivElement>(null)

  const handleOpen = useCallback(() => {
    onOpen()
    onOpenProfile && onOpenProfile()
  }, [])
  const handleClose = useCallback(() => {
    onClose()
    onCloseProfile && onCloseProfile()
  }, [])
  useOnClickOutside(profileRef, handleClose)

  const content = (
    <Flex>
      {user ? (
        <Fragment>
          {isOpen && <Profile ref={profileRef} user={user} />}
          <Avatar
            cursor='pointer'
            visibility={isOpen ? 'hidden' : 'visible'}
            onClick={handleOpen}
            user={user}
          />
        </Fragment>
      ) : (
        <Authentication onAuthenticate={handleAuthentication} />
      )}
    </Flex>
  )

  return content
}
