import React from 'react'
import { Trans } from 'react-i18next'
import { Flex, VStack } from '@chakra-ui/layout'
import styled from '@emotion/styled'

const Container = styled(Flex)`
  justify-content: center;
`

const Content = styled.div`
  box-sizing: border-box;
  min-width: 990px;
  max-width: 1340px;
  padding: 64px 80px 80px;
  flex: 1 1;
  font: 16px/24px TT Norms, sans-serif;
  line-height: 24px;
`

const Text = styled(VStack)`
  white-space: pre-wrap;
  align-items: flex-start;
  width: 100%;
  min-width: 500px;
  max-width: 1000px;
`

const Headline = styled.span`
  display: block;
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 20px;
  font-weight: bold;
`

export function Policy() {
  return (
    <Container>
      <Content>
        <Text spacing='20px'>
          <Trans i18nKey='oficial:policy'>
            <h1>Политика конфиденциальности</h1>
            <p>
              Настоящая Политика конфиденциальности персональной информации (далее - Политика)
              действует в отношении всей информации, которую ООО «Пеликан софтвер» может получить о
              Пользователях и Учениках во время использования ими Сервиса. Использование Сервиса,
              равно как и создание Профиля на Сайте, означает безоговорочное согласие Пользователя с
              настоящей Политикой и указанными в ней условиями сбора и обработки Персональной
              информации; в случае несогласия с этими условиями Пользователь должен воздержаться от
              использования Сервиса.
            </p>

            <p>
              <Headline>1. Термины и определения</Headline>
              1.1. Сервис – все сервисы, предоставляемые ООО «Пеликан софтвер» в соответствии с
              условиями Пользовательского соглашения.
              {'\n'}
              1.2. Сайт - предоставляющий доступ к Сервису сайт, расположенный в сети Интернет по
              адресу https://contest.pelican.study.
              {'\n'}
              1.3. Пользовательское соглашение – соглашение между Пользователем и ООО «Пеликан
              софтвер», содержащее все необходимые и существенные условия предоставления доступа к
              Сервису и использования Сервиса Пользователем, неотъемлемой частью которого является
              настоящая Политика.
              {'\n'}
              1.4. Пользователь - физическое лицо, создавшее Профиль в Сервисе, а также
              согласившееся с условиями Пользовательского соглашения и настоящей Политики.
              Пользователями Сервиса являются Законные представители и Учителя Учеников.
              {'\n'}
              1.5. Профиль - учетная запись Пользователя, содержащая данные о нем и имеющая набор
              прав доступа к Сервису.
              {'\n'}
              1.6. Ученик – несовершеннолетнее физическое лицо, имеющее ограниченный доступ к
              Сервису и возможность его использования в результате предоставления сведений о нем
              Законным представителем Ученика или Учителями.
              {'\n'}
              1.7. Учитель – физическое лицо, осуществляющее доступ к Сервису, обучающее Учеников и
              имеющее право на основании закона или договора предоставлять ООО «Пеликан софтвер»
              Персональную информацию об Учениках.
              {'\n'}
              1.8. Законный представитель Ученика – физическое лицо, осуществляющее доступ к Сервису
              для целей содействия Ученику в получении доступа к Сервису, а также получения сведений
              о результатах использования Учеником Сервиса. Для целей настоящей Политики под
              законным представителем Ученика понимается любой родитель, опекун или попечитель
              несовершеннолетнего, который действует от его имени и в его интересах. Согласие иных
              родителей, опекунов и/или попечителей подразумевается.
              {'\n'}
              1.9. Школа – государственное либо частное образовательное учреждение, в котором
              обучается Ученик.
              {'\n'}
              1.10. Персональная информация – информация о Пользователях и Учениках, перечисленная в
              Разделе 2 настоящей Политики.
            </p>

            <p>
              <Headline>
                2. Персональная информация, которую получает и обрабатывает ООО «Пеликан софтвер»
              </Headline>
              2.1. В рамках настоящей Политики под «Персональной информацией» понимается:
              {'\n'}
              2.1.1. Персональная информация, которую Пользователь предоставляет о себе и Ученике
              самостоятельно при создании Профиля и (или) в процессе использования Сервиса, в том
              числе персональные данные Учителей и Законных представителей Ученика(в частности,
              фамилия, имя, отчество, контактные данные (e-mail), а также Учеников;
              {'\n'}
              2.1.2. Логин и пароль, предоставляемые ООО «Пеликан софтвер» Пользователю после
              заполнения им формы для создания Профиля.
              {'\n'}
              2.1.3. Стандартные данные, автоматически получаемые http-сервером при доступе к Сайту
              и последующих действиях Пользователя (IP-адрес хоста, вид операционной системы
              пользователя, страницы Сайта, посещаемые пользователем).
              {'\n'}
              2.2. Для целей настоящей Политики термин Персональная информация, если иное не
              вытекает напрямую из положений Политики, является синонимом персональных данных, как
              они определены в законодательных актах Российской Федерации.
              {'\n'}
              2.3. ООО «Пеликан софтвер» не обязано проверять достоверность Персональной информации,
              предоставляемой Пользователями, и не осуществляет контроль за их дееспособностью.
              {'\n'}
              2.4. ООО «Пеликан софтвер» исходит из того, что Пользователь:
              {'\n'}
              2.4.1. предоставляет достоверную и достаточную Персональную информацию, в том числе по
              вопросам, предлагаемым в форме для создания Профиля, и поддерживает эту информацию в
              актуальном состоянии;
              {'\n'}
              2.4.2. обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию
              и использовать настоящий Сайт;
              {'\n'}
              2.4.3. осознает, что некоторые виды информации, переданные им другим Пользователям, не
              могут быть удалены самим Пользователем;
              {'\n'}
              2.4.4. ознакомлен с настоящими Правилами, выражает свое согласие с ними и принимает на
              себя указанные в них права и обязанности.
              {'\n'}
              2.5. Отношения, связанные со сбором, хранением, распространением и защитой информации
              о пользователях Сервиса, регулируются настоящей Политикой, иными официальными
              документами администрации Сайта и действующим законодательством Российской Федерации.
            </p>

            <p>
              <Headline>3. Цели сбора и обработки Персональной информации</Headline>
              3.1. Целью настоящей Политики является обеспечение надлежащей защиты Персональной
              информации от несанкционированного доступа и разглашения.
              {'\n'}
              3.2. ООО «Пеликан софтвер» осуществляет обработку Персональной информации в целях
              исполнения Пользовательского соглашения между ООО «Пеликан софтвер» и Пользователем. В
              соответствии со статьей 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных
              данных» отдельное согласие пользователя на обработку его персональных данных не
              требуется. В силу п.п. 2 п. 2 статьи 22 указанного закона ООО «Пеликан софтвер» вправе
              осуществлять обработку персональных данных без уведомления уполномоченного органа по
              защите прав субъектов персональных данных.
              {'\n'}
              3.3. ООО «Пеликан софтвер» строго соблюдает требования законодательства Российской
              Федерации в сфере защиты персональных данных. ООО «Пеликан софтвер» гарантирует, что
              собираемая ООО «Пеликан софтвер» Персональная информация является необходимой и
              достаточной для достижения целей сбора и обработки, изложенных в настоящей Политике.
              {'\n'}
              3.4. ООО «Пеликан софтвер» осуществляет сбор и обработку Персональной информации в
              следующих целях:
              {'\n'}
              3.4.1. Предоставление Пользователям и Ученикам возможности использования Сервиса;
              {'\n'}
              3.4.2. Анализ результатов использования Сервиса (в том числе, сбор и систематизация
              сведений о результатах прохождения Учениками тестов и выполнения ими заданий);
              {'\n'}
              3.4.3. Связь с Пользователем, в том числе направление уведомлений, запросов и
              информации, касающихся использования Сервиса, а также обработка запросов Пользователя;
              {'\n'}
              3.4.4. Улучшение качества Сервиса, удобства его использования, разработка новых
              Сервисов и иной подобной продукции;
              {'\n'}
              3.4.5. Проведение рекламных кампаний;
              {'\n'}
              3.4.6. Предоставление таргетированной рекламы;
              {'\n'}
              3.4.7. Идентификация стороны в рамках соглашений и договоров с ООО «Пеликан софтвер»;
              {'\n'}
              3.4.8. Осуществление Пользователями покупок;
              {'\n'}
              3.4.9. Проведение статистических и иных исследований на основе обезличенных данных.
              {'\n'}
              3.5. ООО «Пеликан софтвер» может разрешить третьим лицам, в том числе издательствам и
              рекламным сетям, размещать рекламу на Сайте. При этом ООО «Пеликан софтвер»
              гарантирует, что в случае размещения указанными лицами файлов Cookie на компьютере
              Пользователя передача Персональной информации не осуществляется. Пользователь вправе в
              любое время самостоятельно изменить настройки приема файлов Cookie в настройках своего
              браузера или отключить их полностью.
            </p>

            <p>
              <Headline>
                4. Порядок обработки Персональной информации и её передачи третьим лицам
              </Headline>
              4.1. Сбор Персональной информации Пользователя осуществляется ООО «Пеликан софтвер»
              при регистрации, а также в дальнейшем при внесении Пользователем по своей инициативе
              дополнительных сведений о себе при использовании Сервисов.
              {'\n'}
              4.2. Персональная информация Пользователей хранится исключительно на электронных
              носителях и обрабатывается с использованием автоматизированных систем, за исключением
              случаев, когда неавтоматизированная обработка персональных данных необходима в связи с
              исполнением требований законодательства.
              {'\n'}
              4.3. ООО «Пеликан софтвер» в процессе обработки Персональной информации осуществляет
              следующие действия: сбор, запись, систематизацию, накопление, хранение, уточнение,
              извлечение, использование, передачу, обезличивание, блокирование, удаление,
              уничтожение Персональной информации и иные действия исключительно в целях исполнения
              Пользовательского соглашения и предоставления доступа к Сервису.
              {'\n'}
              4.4. ООО «Пеликан софтвер» вправе запросить у Пользователя копию документа,
              удостоверяющего личность, либо иного документа, содержащего имя, фамилию, фотографию
              Пользователя, а также иную дополнительную информацию, которая, по усмотрению ООО
              «Пеликан софтвер», будет являться необходимой и достаточной для идентификации такого
              Пользователя и позволит исключить злоупотребления и нарушения прав третьих лиц.
              {'\n'}
              4.5. ООО «Пеликан софтвер» вправе передавать персональные данные третьим лицам с целью
              сбора и анализа статистических данных и для иных целей, указанных в Политике
              конфиденциальности.
              {'\n'}
              4.6. Даже при наличии согласия Пользователя ООО «Пеликан софтвер» не осуществляет
              размещение Персональной информации на общедоступных ресурсах в сети Интернет,
              поскольку такое размещение не отвечает целям сбора и обработки Персональной
              информации. Ни при каких обстоятельствах ООО «Пеликан софтвер» не будет нести
              ответственность за последствия самостоятельного предоставления Пользователем
              Персональной информации о себе и (или) Ученике для общего доступа неограниченному
              кругу лиц, в том числе путем размещения данной информации на общедоступных ресурсах в
              сети Интернет.
              {'\n'}
              4.7. Сервис ориентирован на использование на территории Российской Федерации. Тем не
              менее, Пользователи настоящим предоставляют безусловное согласие на хранение
              Персональной информации на серверах, расположенных за пределами территории Российской
              Федерации. При этом ООО «Пеликан софтвер» понимает, что такое хранение сопровождается
              трансграничной передачей персональных данных и обязуется строго соблюдать
              установленные применимым законодательством правила осуществления такой передачи, в
              частности, без предварительного письменного согласия Пользователя не хранить
              Персональную информацию на серверах, расположенных на территории иностранных
              государств, не обеспечивающих адекватной защиты прав субъектов персональных данных.
              ООО «Пеликан софтвер» гарантирует, что в каждом случае трансграничной передачи
              Персональной информации будут приниматься все требуемые применимым законодательством
              меры по охране конфиденциальности; при этом уровень принимаемых мер по охране
              конфиденциальности в любом случае не ниже уровня охраны, установленного
              законодательством Российской Федерации.
              {'\n'}
              4.8. ООО «Пеликан софтвер» применяет разумные и достаточные меры для защиты
              Персональной информации Пользователей. При этом Пользователь понимает и соглашается,
              что в сети Интернет не может быть обеспечена абсолютная защита информации от угроз,
              которые в ней существуют. Настоящим Пользователь дает свое безусловно и безотзывное
              согласие ООО «Пеликан софтвер» определять достаточный уровень защиты персональных
              данных, способы и место (территорию) их хранения.
              {'\n'}
              4.9. Пользователь принимает и соглашается, что ООО «Пеликан софтвер» ни при каких
              обстоятельствах не должно нести ответственность за утрату и/или распространения
              персональных данных, если такая утрата и/или распространение стали результатом
              действий, вследствие вины или небрежности, третьих лиц. ООО «Пеликан софтвер» окажет
              Пользователю все разумную помощь и содействие в защите его прав и законных интересов в
              судебных разбирательствах с такими третьими лицами.
            </p>

            <p>
              <Headline>5. Персональная информация Учеников и защита интересов детей</Headline>
              5.1. ООО «Пеликан софтвер» серьезно относится к защите информации о несовершеннолетних
              лицах и стремиться защищать конфиденциальность указанной информации. ООО «Пеликан
              софтвер» призывает Учеников не создавать Профили и не осуществлять использование
              Сервиса, а также не предоставлять какие-либо сведения о себе без разрешения и участия
              Законных представителей Ученика и Учителей. Условия использования Сервиса, изложенные
              в Пользовательском соглашении, обязывают Законных представителей Ученика и Учителей
              обеспечивать постоянный и непосредственный контроль за всеми действиями Учеников при
              использовании Сервиса.
              {'\n'}
              5.2. Школа, в лице ее представителей Учителей, является оператором персональных данных
              Учеников, предоставляющим Персональную информацию об Учениках только при наличии
              согласия Законных представителей Ученика как законных представителей Учеников; с
              момента получения уведомления Школы об отзыве такого согласия ООО «Пеликан софтвер»
              незамедлительно прекращает обработку Персональной информации как предоставленной
              Школой непосредственно, так и Учителями как представителями Школы, действующими от ее
              имени и в ее интересе на основании закона или договора.
              {'\n'}
              5.3. При сборе и обработке Персональной информации об Учениках ООО «Пеликан софтвер»
              исходит из того, что:
              {'\n'}
              5.3.1. Предоставляя Персональную информацию об Ученике, Законный представитель Ученика
              дает ООО «Пеликан софтвер» безусловное согласие на обработку персональных данных
              Ученика, выступая в качестве его законного представителя, либо подтверждает, что им
              было получено согласие Ученика или его законного представителя на обработку
              персональных данных и передачу их третьим лицам в целях, указанных в настоящей
              Политике и Пользовательском соглашении.
              {'\n'}
              5.3.2. Предоставляя Персональную информацию об Учениках, Учитель подтверждает, что им
              или Школой было получено согласие Учеников или их законных представителей на обработку
              персональных данных и передачу их третьим лицам в целях, указанных в настоящей
              Политике и Пользовательском соглашении.
              {'\n'}
              5.4. Законные представители Ученика регулярно проверяют содержание информации об
              Учениках, размещаемой в Сервисе, и могут в любое время связаться с ООО «Пеликан
              софтвер», если желают удалить ее и (или) потребовать, чтобы в дальнейшем информация об
              их ребенке не собиралась и не использовалась. Кроме того, ООО «Пеликан софтвер»
              осуществляет ознакомление Законных представителей Ученика с дополнительными мерами
              защиты Персональной информации об Учениках в случае направления ими соответствующего
              письменного запроса на адрес: info@education-team.ru. ООО «Пеликан софтвер» прилагает
              все усилия для проверки личности лица, которое запрашивает информацию о ребенке, чтобы
              удостовериться, что это лицо на самом деле является Законным представителем ребенка.
              {'\n'}
              5.5. ООО «Пеликан софтвер» осознает важность и необходимость защиты детей от
              информации, способной причинить вред их здоровью и (или) развитию. Вся размещаемая на
              Сайте информация безопасна для детей и не имеет возрастных ограничений. ООО «Пеликан
              софтвер» прилагает все возможные усилия для недопущения размещения Пользователями и
              (или) третьими лицами на Сайте информации, в том числе рекламных материалов, которая в
              соответствии с Федеральным законом от 29.12.2010 N 436-ФЗ «О защите детей от
              информации, причиняющей вред их здоровью и развитию» запрещена для распространения
              среди детей и (или) распространение которой среди детей определенных возрастных
              категорий ограничено.
            </p>

            <p>
              <Headline>6. Изменение Персональной информации</Headline>
              6.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную
              им Персональную информацию или её часть в своем Профиле.
              {'\n'}
              6.2. Пользователь также может удалить Персональную информацию, предоставленную им в
              ходе создания Профиля и (или) использования Сервиса. При этом Пользователь осознает и
              согласен с тем, что такое удаление может повлечь невозможность использования Сервиса.
            </p>

            <p>
              <Headline>7. Меры, применяемые для защиты Персональной информации</Headline>
              7.1. ООО «Пеликан софтвер» принимает технические и организационно-правовые меры в
              целях обеспечения защиты персональных данных Пользователя от неправомерного или
              случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
              распространения, а также от иных неправомерных действий.
              {'\n'}
              7.2. ООО «Пеликан софтвер» принимает необходимые и достаточные организационные и
              технические меры для защиты Персональной информации от неправомерного или случайного
              доступа, уничтожения, изменения, блокирования, копирования, распространения, а также
              от иных неправомерных действий с ней третьих лиц. В то же время в целях обеспечения
              возможности нормального использования Сервиса ООО «Пеликан софтвер» пользуется
              услугами третьих лиц. Ни при каких обстоятельствах ООО «Пеликан софтвер» не будет
              нести ответственность за нарушение конфиденциальности Персональной информации,
              наступившей случайно или в результате неправомерных действий с ней третьих лиц.
              Пользователь осознает и понимает, что вся Персональная информация предоставляется им
              на свой риск и под свою личную ответственность, и в случае любых неправомерных
              действий третьих лиц в отношении Персональной информации Пользователь соглашается
              претензии и иски непосредственно к таким лицам.
            </p>

            <p>
              <Headline>
                8. Внесение изменений в Политику. Согласие Пользователя с Политикой
              </Headline>
              8.1. Регистрируясь и используя Сайт и Сервис, Пользователь выражает свое согласие с
              условиями Настоящей Политики.
              {'\n'}
              8.2. В случае несогласия Пользователя с условиями настоящих Правил использование Сайта
              и Сервисов должно быть немедленно прекращено.
              {'\n'}
              8.3. Пользователь признает и соглашается, что создание Профиля и последующее
              использование Сервиса означает безоговорочное согласие Пользователя со всеми пунктами
              настоящей Политики и безоговорочное принятие ее условий. Продолжение использования
              Пользователем Сервиса после любых изменений настоящей Политики означает его согласие с
              такими изменениями и (или) дополнениями. Пользователю следует регулярно знакомиться с
              содержанием настоящей Политики в целях своевременного ознакомления с ее изменениями.
              {'\n'}
              8.4. ООО «Пеликан софтвер» имеет право вносить изменения и (или) дополнения в
              настоящую Политику в любое время без предварительного и (или) последующего уведомления
              Пользователя. При внесении изменений в актуальной редакции указывается дата последнего
              обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное
              не предусмотрено новой редакцией Политики.
              {'\n'}
              8.5. Пользователь самостоятельно отслеживает изменения Политики и знакомится с
              действующей редакцией Политики. Продолжение использования Сервиса Пользователем после
              внесения изменений и/или дополнений в настоящую Политику означает принятие и согласие
              Пользователя с такими изменениями и/или дополнениями.
            </p>

            <p>
              <Headline>9. Заключительные положения. Контактная информация</Headline>
              9.1. К настоящей Политике и отношениям между Пользователями и ООО «Пеликан софтвер»,
              возникающим в связи с применением Политики, подлежит применению право Российской
              Федерации. К настоящей Политике имеют доступ все Пользователи без исключения.
              {'\n'}
              9.2. Все возникающие вопросы, отзывы, предложения и замечания относительно настоящей
              Политики просьба направлять по следующему адресу электронной почты ООО «Пеликан
              софтвер» info@education-team.ru.
            </p>
          </Trans>
        </Text>
      </Content>
    </Container>
  )
}

export default Policy
