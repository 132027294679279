import React from 'react'
import { Trans } from 'react-i18next'
import { Flex, VStack } from '@chakra-ui/layout'
import styled from '@emotion/styled'

const Container = styled(Flex)`
  justify-content: center;
`

const Content = styled.div`
  box-sizing: border-box;
  min-width: 990px;
  max-width: 1340px;
  padding: 64px 80px 80px;
  flex: 1 1;
  font: 16px/24px TT Norms, sans-serif;
  line-height: 24px;
`

const Text = styled(VStack)`
  white-space: pre-wrap;
  align-items: flex-start;
  width: 100%;
  min-width: 500px;
  max-width: 1000px;
`

const Headline = styled.span`
  display: block;
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 20px;
  font-weight: bold;
`

export function Rules() {
  return (
    <Container>
      <Content>
        <Text spacing='20px'>
          <Trans i18nKey='oficial:rules'>
            <h1>Условия использования</h1>
            <p>
              Предметом настоящего Пользовательского соглашения являются отношения между ООО
              «Пеликан софтвер» ( ОГРН 1127746714590, место регистрации: 117342, г. Москва улица
              Введенского, д.23А, стр.3, пом.ХХ, комн.64, ИНН: 7728818122/КПП: 772801001, тел.
              8-800-550-07-85), далее – Пеликан софтвер, являющегося законным правообладателем
              цифровой образовательной среды «Пеликан плюс» (далее – Сервис), доступной по адресу:
              https://contest.pelican.study/, с одной стороны и Вами, далее «Пользователь», с другой
              стороны, совместно именуемые «Стороны», а по отдельности «Сторона», по поводу
              предоставления и использования Сервиса.
            </p>
            <p>
              Пеликан софтвер предлагает Пользователю использовать Сервис, доступный по адресу:
              https://contest.pelican.study/, на условиях, изложенных в настоящем Пользовательском
              соглашении (далее — «Соглашение»).
            </p>
            <p>
              <Headline>1. Общие положения</Headline>
              1.1. В настоящем документе и вытекающих или связанных с ним отношениях Сторон
              применяются следующие термины и определения:
              {'\n'}
              Сервис – цифровая образовательная среда«Пеликан плюс», право пользования которой
              предоставляется Пеликан софтвер Пользователям на условиях настоящего Пользовательского
              соглашения.
              {'\n'}
              Сайт - совокупность программ для электронных вычислительных машин и иной информации,
              содержащейся в информационной системе, предоставляющая доступ к Сервису посредством
              информационно-телекоммуникационной сети "Интернет" по адресу
              https://contest.pelican.study/.
              {'\n'}
              Пользовательское соглашение – настоящий договор между Пользователем Сервиса и Пеликан
              софтвер, содержащий все необходимые условия предоставления доступа к Сервису и
              использования Сервиса Пользователем.
              {'\n'}
              Аккаунт - уникальная учетная запись Пользователя в Сервисе, состоящая из логина и
              пароля и предоставляющая доступ к Сервису. Аккаунт связан с Профилем Пользователя.
              {'\n'}
              Пользователь - физическое лицо, имеющее Аккаунт в Сервисе и определенную Роль в
              Сервисе, а также согласившееся с условиями Пользовательского соглашения.
              {'\n'}
              Профиль Пользователя - информация о Пользователе Сервиса, заполняемая им и содержащая
              данные о нем и о его Роли в Сервисе.
              {'\n'}
              Роль Пользователя - набор функциональных возможностей Пользователя в Сервисе. В
              Сервисе предусмотрены следующие Роли Пользователя: «Учитель», «Ученик», «Посетитель».
              {'\n'}
              Ученик - Роль Пользователя в Сервисе, предоставляемая несовершеннолетнему физическому
              лицу, проходящему обучение в Сервисе с ведома и согласия своего законного
              представителя.
              {'\n'}
              Учитель - Роль Пользователя в Сервисе, предоставляемая педагогам и иным работникам в
              сфере образования с целью обучения Учеников на Сервисе.
              {'\n'}
              Посетитель - Роль Пользователя в Сервисе, предоставляемая любому физическому лицу, с
              целью ознакомления с Сервисом, до первичной регистрации или последующей авторизации и
              заключения пользовательского соглашения.
              {'\n'}
              Законный представитель Ученика - родители, усыновители, опекуны и попечители, приемные
              родители, органы опеки и попечительства и иные представители, действующие от имени
              Ученика и в его интересах и определенные действующим законодательством РФ.
              {'\n'}
              Регулирующие документы - настоящее Пользовательское соглашение, Политика
              конфиденциальности ((https://contest.pelican.study/pages/privacy/) и оферты,
              определяющие условия и порядок использования Пользователем некоторых функциональных
              возможностей Сервиса. Пользователь обязуется ознакомиться со всеми Регулирующими
              документами и самостоятельно несет риск неознакомления.
              {'\n'}
              1.2. Пеликан софтвер несет ответственность за весь контент, размещенный на Сервисе,
              выступая в качестве его правообладателя или лица, имеющего право использования по
              лицензионному договору. Соглашение вступает в силу с момента выражения Пользователем
              согласия с его условиями в порядке, предусмотренном п. 1.4 Соглашения.
              {'\n'}
              1.3. Текст Соглашения может быть изменен Пеликан софтвер в одностороннем порядке без
              какого-либо специального уведомления Пользователя. Новая редакция Соглашения вступает
              в силу с даты ее размещения в сети Интернет, указанной на странице по адресу
              https://contest.pelican.study/pages/rules/. На указанной странице всегда размещена
              действующая редакция Соглашения.
              {'\n'}
              1.4. Настоящее Соглашение распространяется на всех Пользователей Сервиса, независимо
              от целей его использования. Действие Соглашения распространяется на Пользователей с
              момента первичной регистрации на Сервисе или последующей авторизации. Нажатие кнопок
              «Зарегистрироваться» или «Войти» обозначает полное и безоговорочное согласие
              Пользователя с настоящим Соглашением. В случае несогласия Пользователя с какими-либо
              из положений Соглашения, Пользователь не вправе использовать Сервис. В случае если
              Пеликан софтвер были внесены какие-либо изменения в Соглашение в порядке,
              предусмотренном пунктом 1.3 Соглашения, с которыми Пользователь не согласен, он обязан
              немедленно прекратить использование Сервиса.
            </p>
            <p>
              <Headline>2. Регистрация Пользователя. Учетная запись Пользователя</Headline>
              2.1. Для того чтобы воспользоваться отдельными функциями Сервиса Пользователю
              необходимо пройти процедуру регистрации, в результате которой для Пользователя будет
              создан Аккаунт и связанный с ним Профиль Пользователя. Регистрация или заполнение
              Профиля в Сервисе Ученика производится его Законным представителем. Дальнейшее
              использование Сервиса Учеником производится с согласия и под руководством Законного
              представителя Ученика.
              {'\n'}
              2.2. При регистрации и заполнении данных в Профиле Пользователь обязуется предоставить
              достоверную и полную информацию о себе по вопросам, предлагаемым в форме регистрации,
              и поддерживать эту информацию в актуальном состоянии. Если Пользователь в процессе
              регистрации предоставляет неверную информацию или у Пеликан софтвер есть основания
              полагать, что предоставленная Пользователем информация неполна или недостоверна, он
              имеет право по своему усмотрению заблокировать либо удалить учетную запись
              Пользователя и отказать Пользователю в использовании Сервиса или его отдельных
              функций.
              {'\n'}
              2.3. Персональная информация Пользователя, содержащаяся в Профиле Пользователя
              обрабатывается Пеликан софтвер в соответствии с условиями Политики конфиденциальности.
              Согласие на обработку персональных данных от имени Ученика выражается Законным
              представителем Ученика при регистрации Ученика в Сервисе и/или заполнении Профиля
              Ученика.
              {'\n'}
              2.4. Средства для доступа к учетной записи Пользователя.
              {'\n'}
              2.4.1. При регистрации Пользователя Ролью Учитель Пользователь указывает свою
              контактную почту, которая будет совпадать с его логином и пароль для доступа к учетной
              записи. Пеликан софтвер вправе запретить использование определенных логинов, а также
              устанавливать требования к логину и паролю (длина, допустимые символы и т.д.) В случае
              утраты пароля Пользователь может пройти процедуру восстановления пароля.
              {'\n'}
              2.4.1.1. Регистрация Пользователя с Ролью Учитель может осуществляться на основании
              направленной заявки от учебного заведения. В этом случае Пеликан софтвер направляет
              Пользователю логин и пароль. При этом пароль может быть изменён Пользователем с Ролью
              Учитель самостоятельно.
              {'\n'}
              2.4.2 Аккаунт с Ролью Ученик может быть создан:
              {'\n'}
              1) При регистрации Пользователя с Ролью Ученик, которая осуществляется Законным
              представителем Ученика. Законный представитель Ученика указывает свою контактную
              почту, персональные данные Ученика. Эти данные хранятся в Профиле Ученика. Логин и
              пароль создаются автоматически на основании введенных данных.
              {'\n'}
              2) При использовании Сервиса Учителем в некоторых ситуациях ему предоставляются
              Аккаунты с Ролью Ученик, Профили которых не содержат персональных данных Учеников.
              Учитель может выдать доступ Законному представителю Ученика (логин и пароль Аккаунта).
              При первой авторизации под этим Аккаунтом Законный представитель Ученика заполняет
              Профиль Ученика, предоставляя данные о нем.
              {'\n'}
              2.5. Пользователь и (или) Законный представитель Ученика, в зависимости от способа
              регистрации Ученика, несут персональную ответственность за сохранность и безопасность
              своего пароля и персональной информации Пользователя и (или) Ученика. Все действия в
              Сервисе, совершенные под Профилем Пользователя считаются совершенными Пользователем.
              {'\n'}
              2.6. Пользователь обязуется использовать Сервис только в личных некоммерческих целях в
              соответствии с условиями настоящего Соглашения, если другое не согласовано
              предварительно письменно в отдельном порядке с Пеликан софтвер.
              {'\n'}
              2.7. Удаление Профиля Пользователя из Сервиса может быть произведено Пеликан софтвер
              при обращении Пользователя и (или) Законного представителя Ученика по следующему
              адресу электронной почты: info@education-team.ru.
              {'\n'}
              2.8. Все претензии, запросы, вопросы, любая иная корреспонденция, должны направляться
              Пользователями для Пеликан софтвер по следующему адресу электронной почты:
              info@education-team.ru.
            </p>
            <p>
              <Headline>3. Права и обязанности сторон</Headline>
              3.1. Пеликан софтвер предоставляет Пользователям доступ к Сервису в сети Интернет с
              обеспечением необходимого уровня безопасности доступа и осуществлением перерывов на
              техническое обслуживание. Пеликан софтвер вправе привлекать третьих лиц для
              предоставления Сервиса Пользователям без уведомления/согласия последних.
              {'\n'}
              3.2. Пеликан софтвер предоставляет возможность Пользователям использовать материалы
              Пеликан софтвер только на Сервисе и исключительно способами, не противоречащими
              законодательству Российской Федерации и Регулирующим документам.
              {'\n'}
              3.3. Пользователь соглашается с принятием на себя обязанности немедленно уведомить
              Пеликан софтвер путем направления письма на электронную почту info@education-team.ru о
              любом нарушении безопасности, связанном с доступом к Сервису, совершенном с
              использованием логина и пароля Пользователя без его ведома и согласия. Пеликан софтвер
              не несет ответственности за какие-либо нарушения безопасности, в том числе потерю или
              порчу данных Пользователя, произошедшие в результате доступа третьих лиц к Сервису с
              помощью логина и пароля Пользователя.
              {'\n'}
              3.4. По запросу Пользователя Пеликан софтвер предоставляет автоматизированные средства
              по восстановлению пароля. При этом Пеликан софтвер сохраняет за собой право
              ограничивать использование этих средств, а также временно или полностью
              приостанавливать их работу. Пеликан софтвер не несет ответственности за потерю
              информации для доступа к Сервису, совершенную по вине Пользователя, а также за любые
              действия или бездействия Пользователя, которые могут привести к такой потери.
              {'\n'}
              3.5. Пользователь соглашается с правом Пеликан софтвер запрещать использование
              некоторых логинов и блокировать доступ в Систему с их использованием. Пользователю
              рекомендуется выбирать пароль достаточной сложности, чтобы избежать возможности его
              подбора третьими лицами.
              {'\n'}
              3.6. Пользователь обязуется соблюдать условия настоящего Соглашения и соглашается с
              тем, что Пеликан софтвер имеет право по собственному усмотрению и без специального
              уведомления или предупреждения Пользователя ограничивать его доступ к Сервису по
              причине нарушения условий настоящего Соглашения.
              {'\n'}
              3.7. Пеликан софтвер сохраняет за собой право, но не обязан проводить модерацию всех
              материалов, размещённых Пользователями в Сервисе, то есть следить за содержанием этих
              материалов и, в случае необходимости, по собственному усмотрению, без предупреждения и
              объяснения причин удалять или перемещать их.
              {'\n'}
              3.8. Пользователь соглашается с тем, что Пеликан софтвер вправе по собственному
              усмотрению и без предварительного или последующего уведомления Пользователя и
              объяснения причин блокировать доступ Пользователя к Сервису путем удаления Аккаунта
              Пользователя при нарушении им условий настоящего Соглашения, либо норм действующего
              законодательства РФ, в том числе при нарушении охраняемых законом прав третьих лиц в
              случае поступления от них мотивированной жалобы.
              {'\n'}
              3.9. Если иное прямо не предусмотрено нормами действующего законодательства РФ, в
              случае нарушения Пользователем условий Соглашения Пеликан софтвер имеет право
              предпринимать меры, необходимые для защиты этих условий и своих интересов вне
              зависимости от срока давности этих нарушений. Таким образом, бездействие со стороны
              Пеликан софтвер при нарушении Пользователями условий Соглашения не лишает Пеликан
              софтвер права предпринять необходимые для защиты своих интересов действия позднее и не
              говорит об отказе Пеликан софтвер от защиты своих прав при совершении подобных
              нарушений в будущем.
              {'\n'}
              3.10. Пеликан софтвер может предоставлять Пользователям лицензию на некоторые
              виртуальные товары и дополнительные сервисы, подлежащие использованию в рамках Сервиса
              в соответствии с условиями оферт.
              {'\n'}
              3.11. Настоящим Законные представители Учеников соглашаются, заверяют и гарантируют,
              что все действия, совершенные Учеником при использовании Сервиса, будут предварительно
              одобряться их Законными представителями. Настоящим устанавливается презумпция
              одобрения Законными представителями Учеников всех действий Учеников при использовании
              Сервиса.
              {'\n'}
              3.12. Настоящим Законные представители Учеников и Учителя заверяют и гарантируют
              Пеликан софтвер, что они будут контролировать действия Учеников при использовании
              Сервиса. Все действия Учеников будут совершаться под их непосредственным контролем, с
              их безусловного согласия и одобрения.
              {'\n'}
              3.13. Законный представитель Ученика обязуется не допускать использование Сервиса
              Учеником без его согласия, одобрения. Законный представитель Ученика обязуется
              контролировать каждый случай использования Сервиса Учеником. Законный представитель
              Ученика отвечает за все последствия несоблюдения п.3.11- 3.12 настоящего Соглашения.
              Законный представитель Ученика признает и соглашается, что Пеликан софтвер не может
              контролировать и не принимает на себя обязательств по контролю доступа Ученика к
              Сервису. Все действия, совершенные Учеником создают права и обязанности для его
              Законных представителей.
            </p>
            <p>
              <Headline>4. Интеллектуальная собственность</Headline>
              4.1. Все материалы, представленные на Сервисах, включая изображения, программный код,
              логотипы, графику, звуки, видео файлы, задания, коллекции заданий, методические
              материалы (представленные в любом из возможных видов передачи информации) (далее –
              Интеллектуальная собственность) являются Интеллектуальной собственностью Пеликан
              софтвер или используются Пеликан софтвер на законных основаниях и защищены в
              соответствии с законодательством Российской Федерации об интеллектуальной
              собственности.
              {'\n'}
              4.2. В соответствии с условиями настоящего Соглашения, Пеликан софтвер настоящим
              предоставляет Пользователю право на использование Интеллектуальной собственности
              исключительно для личного некоммерческого использования. Права использования
              Интеллектуальной собственности, предоставляемые согласно настоящему Соглашению,
              обусловлены соблюдением Пользователем настоящего Соглашения. Пользователь настоящим
              признает, что в рамках настоящего Соглашения не происходит передача или уступка прав
              на Интеллектуальную собственность, и настоящее Соглашение нельзя толковать как
              передачу каких-либо исключительных прав на Интеллектуальную собственность.
              {'\n'}
              4.3. Пеликан софтвер оставляет за собой все права (право использования и лицензионные
              права на Сервис и Интеллектуальную собственность, принадлежащую ему и/или используемую
              на законном основании, включая, в том числе, все авторские права, права на товарные
              знаки, ноу-хау, фирменные наименования, вещные права, патенты, программный код,
              аудиовизуальные эффекты, темы оформления, сюжеты, художественное оформление,
              графические изображения, звуковые эффекты, музыкальные произведения и задания как
              зарегистрированные, так и незарегистрированные, а также все приложения и дополнения и
              модификации к ним. Использование Интеллектуальной собственности, а также каких-либо
              иных элементов Сервиса возможно только в рамках функционала, предлагаемого Сервисом.
              Использование Интеллектуальной собственности вне функционала Сервиса без
              предварительного письменного согласия Пеликан софтвер запрещено. Если иное прямо не
              разрешено нормами законодательства РФ, Интеллектуальную собственность и Сервис нельзя
              копировать, воспроизводить, перерабатывать, изменять, модифицировать или
              распространять каким-либо образом на любых носителях, полностью или частично, без
              предварительного письменного согласия Пеликан софтвер и/или иных правообладателей
              охраняемых прав. Все права, не предоставленные Пользователю в рамках настоящего
              Соглашения в явно выраженной форме, сохраняются за Пеликан софтвер.
              {'\n'}
              4.4. При использовании Сервисов запрещены загрузка, передача между Пользователями,
              изменение или размещение информации, данных или изображений, которые нарушают
              интеллектуальные права третьих лиц.
              {'\n'}
              4.5. Пользователь несет ответственность в случае нарушения им интеллектуальных прав
              третьих лиц, в частности авторских и смежных с ними прав, а также исключительных прав
              на изобретение, полезную модель, промышленный образец или товарный знак.
              {'\n'}
              4.6. На Сервисе Пользователь вправе публиковать только те комментарии (включая
              информацию, изображение профиля, текст комментария и прикладываемые фотографии),
              которые не нарушают действующее законодательство РФ и/или права третьих лиц. В случае
              если такое произведение является объектом авторского права или включает его,
              Пользователь гарантирует, что имеет право на такое использование объекта авторского
              права. Публикуя указанные объекты, Пользователь предоставляет Пеликан софтвер право
              использовать их без ограничения по территории и сроку путем воспроизведения,
              распространения, перевода, публичного исполнения, публичного показа, доведения до
              всеобщего сведения.
              {'\n'}
              4.7. Пеликан софтвер оставляет за собой право блокировать любую информацию,
              размещенную Пользователем на Сервисе, нарушающую исключительные и/или личные
              неимущественные права третьих лиц, в том числе авторские и смежные с ними права, по
              требованию их правообладателя либо без такового.
            </p>
            <p>
              <Headline>5. Персональные данные</Headline>
              5.1. Пеликан софтвер для исполнения положений настоящего Соглашения и предоставления
              Пользователям доступа к Сервису обрабатывают с соблюдением требований Федерального
              закона от 27.07.2006 N 152-ФЗ «О персональных данных» (далее – ФЗ о персональных
              данных) их персональные данные, полученные от Пользователей и (или) Законных
              представителей Ученика на законном основании с соблюдением требований Федерального
              закона от 27.07.2006 N 152-ФЗ «О персональных данных» (далее – ФЗ о персональных
              данных). Принципы, порядок и условия обработки персональных данных определены Пеликан
              софтвер в настоящем Соглашении и Политике конфиденциальности.
              {'\n'}
              5.2. Целью обработки персональных данных Пользователей, являются предоставление
              возможности использования Сервиса, анализ результатов использования Сервиса (в том
              числе, сбор и систематизация сведений о результатах использования контента),
              проведение рекламных кампаний, предоставление таргетированной рекламы, проведение
              статистических исследований и анализа полученных статистических данных, осуществление
              иных действий, описанных в Соглашении и соответствующих разделах сайта. Обработка
              персональных данных ограничивается достижением вышеуказанных целей. Обработке подлежат
              только персональные данные, которые отвечают целям их обработки. Пеликан софтвер
              принимает меры по обеспечению безопасности персональных данных при их обработке,
              указанные в статье 19 ФЗ о персональных данных.
              {'\n'}
              5.3. Обработка персональных данных Пользователя осуществляется Пеликан софтвер с
              момента регистрации Аккаунта Пользователя и в течение 3 (трех) лет с момента удаления
              Аккаунта Пользователем.
              {'\n'}
              5.4. Пользователь соглашается с тем, что Пеликан софтвер в процессе обработки
              персональных данных, выступая оператором по обработке персональных данных, имеет право
              осуществлять с персональными данными любое действие (операцию) или совокупность
              действий (операций), совершаемых с использованием средств автоматизации или без
              использования таких средств, включая сбор, запись, систематизацию, накопление,
              хранение, уточнение (обновление, изменение), извлечение, использование, передачу
              (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных в целях исполнения Соглашения и предоставления доступа
              к Сервису.
              {'\n'}
              5.5. Пеликан софтвер вправе разрешить третьим лицам, в том числе издателям и рекламным
              сетям, показывать рекламу на Сайте. Некоторые, из указанных третьих лиц, могут
              размещать cookie-файлы на компьютере Пользователя, чтобы идентифицировать указанный
              компьютер каждый раз, когда они посылают Пользователю онлайн-рекламу. Это дает им
              возможность понять, где Пользователь видел их рекламу, и показывать рекламу, которую
              они считают интересной для Пользователя. Передача персональных данных Пользователя
              указанным третьим лицам при этом не осуществляется. Если Пользователь не желает, чтобы
              указанная информация была доступна для третьих лиц, он вправе запретить сбор данных
              путем изменения настроек на своём персональном компьютере.
              {'\n'}
              5.6. Пользователь и (или) Законный представитель Ученика вправе в любой момент
              отозвать согласие на обработку своих персональных данных или персональных данных
              Учеников, законным представителем которых он является, направив в свободной форме
              письмо по адресу: info@education-team.ru. Пеликан софтвер обязуется рассмотреть
              поступившее обращение и в срок не более 30 календарных дней уничтожить все
              персональные данные Пользователя или Учеников, запрос на удаление которых был получен.
              {'\n'}
              5.7. Пеликан софтвер применяет разумные и достаточные меры для защиты персональных
              данных Пользователя. При этом Пользователь понимает и соглашается, что в сети Интернет
              не может быть обеспечена абсолютная защита информации от угроз, которые в ней
              существуют. Настоящим Пользователь дает свое безусловно и безотзывное согласие Пеликан
              софтвер определять достаточный уровень защиты персональных данных, способы и место
              (территорию) их хранения в соответствии с требованиями действующего законодательства
              Российской Федерации.
              {'\n'}
              5.8. Пользователь принимает и соглашается, что Пеликан софтвер ни при каких
              обстоятельствах не должен нести ответственность за утрату и/или распространение
              персональных данных, если такая утрата и/или распространение стали результатом
              действий вследствие вины или небрежности третьих лиц. Пеликан софтвер окажет
              Пользователю всю возможную помощь и содействие в защите его прав и законных интересов
              в судебных разбирательствах с такими третьими лицами.
            </p>
            <p>
              <Headline>6. Запрещенное использование сервиса</Headline>
              6.1. Пользователю запрещается:
              {'\n'}
              6.1.1. Загружать, размещать, передавать с помощью программных средств сайта, и/или
              любого другого способа публикации текстов, программ, изображений или материалов
              пропагандирующих террористическую деятельность, или разжигающих межнациональную,
              расовую или религиозную рознь, а также отображающих или пропагандирующих насилие или
              смерть, содержащих описание или изображение причинения вреда несовершеннолетним лицам
              в любой форме, включая любые формы порнографии.
              {'\n'}
              6.1.2. Загружать, размещать, передавать с помощью программных средств Сервиса, или
              любого другого способа публикации текстов, программ, изображений или материалов
              содержащих непристойные материалы и информацию, в любой форме побуждающей к незаконным
              действиям, включая азартные игры, нелегальную продажу оружия или наркотиков.
              {'\n'}
              6.1.3. Совершать или допускать совершение преступных деяний, преследуемых в
              соответствии с законодательством Российской Федерации.
              {'\n'}
              6.1.4. Загружать, хранить, использовать или передавать любую рекламную информацию,
              описание мошеннических схем, систем заработка в сети Интернет и так далее.
              {'\n'}
              6.1.5. Выдавать себя за другого человека или представителя организации и/или
              сообщества, а также вводить других Пользователей в заблуждение.
              {'\n'}
              6.1.6. Любым способом препятствовать работе Сервиса, в том числе создавать,
              распространять или использовать компьютерные программы либо иную компьютерную
              информацию, предназначенную для несанкционированного уничтожения, блокирования,
              модификации, копирования компьютерной информации или нейтрализации средств защиты
              компьютерной информации, а также предназначенные для нарушения, уничтожения либо
              ограничения функциональности любого компьютерного или телекоммуникационного
              оборудования или программ (компьютерные вирусы), для осуществления
              несанкционированного доступа, а также серийные номера к коммерческим программным
              продуктам и программы для их генерации, логины, пароли и прочие средства для получения
              несанкционированного доступа к Сервису, а также размещения ссылок на вышеуказанную
              информацию.
              {'\n'}
              6.2. Используя Сервис, Пользователь обязуется не нарушать его информационную
              безопасность, а именно не совершать следующих действий:
              {'\n'}● не осуществлять попытки проверить уязвимость систем безопасности Сервиса, не
              нарушать процедуры регистрации в Сервисе;
              {'\n'}● не осуществлять попытки создания помех в использовании Сервиса другими
              Пользователями, включая распространение компьютерных вирусов, порчу данных,
              повторяющуюся рассылку информации, одновременную отправку большого количества
              электронной почты и/или запросов к Сервисам с целью намеренно вывести сервер сайта из
              строя и иные действия, выходящие за рамки нормального целевого использования Сервиса,
              и способные умышленно или по неосторожности повлечь сбои в его работе;
              {'\n'}● не рассылать Пользователям Сервиса материалы, на которые они не давали своего
              согласия, «спам», любые письма и рекламу;
              {'\n'}● не использовать или не пытаться использовать любое программное обеспечение,
              или процедуры для навигации или поиску в Сервисе, кроме встроенной в Систему поисковой
              машины и традиционных и общедоступных браузеров.
            </p>
            <p>
              <Headline>7. Ограничение ответственности</Headline>
              7.1. Пеликан софтвер не несет ответственности в случае прямых или косвенных финансовых
              или иных потерь Пользователей, вызванных временными перебоями в работе Сервиса по
              следующим причинам: плановые или внеплановые технические и профилактические работы,
              технические сбои интернет - провайдеров, компьютерных сетей, серверов и средств, а
              также противоправные действия третьих лиц умышленные, неумышленные (в том числе
              неосторожные) действия самого Пользователя и/или форс-мажорные обстоятельства. При
              этом Пеликан софтвер берет на себя обязательство приложить максимум усилии для
              восстановления работоспособности Сервиса в кратчайшие сроки.
              {'\n'}
              7.2. Пеликан софтвер ни при каких обстоятельствах не несет ответственности за
              нарушение интеллектуальных прав третьих лиц, если такое нарушение было вызвано
              созданием, загрузкой, размещением, копированием или изменением информации, материалов
              или изображений в Сервисе, выполненных Пользователями Сервиса.
              {'\n'}
              7.3. Пеликан софтвер ни при каких обстоятельствах не несет ответственности за
              нарушение требований законодательства РФ, регламентирующего порядок обращения с
              персональными данными, если такое нарушение наступило вследствие нарушения
              Пользователями требований законодательства РФ и условий настоящего Соглашения.
              {'\n'}
              7.4. Данное ограничение ответственности должно применяться в максимальной мере,
              разрешенной законом. Данное ограничение ответственности не применяется исключительно в
              тех случаях, когда какое-либо конкретное положение ограничения ответственности прямо
              запрещено императивными нормами законодательства РФ.
              {'\n'}
              7.5. Ни при каких обстоятельствах Пеликан софтвер не будет нести ответственность за
              все убытки (за исключением случаев, предусмотренных действующим законодательством РФ),
              превосходящие фактическую цену, уплаченную Пользователем за использование Сервиса, или
              2000 (двух тысяч) российских рублей - в зависимости от того, какая сумма является
              меньшей.
            </p>
            <p>
              <Headline>8. Гарантии</Headline>
              8.1. Сервис предоставляется Пользователю «как есть», со всеми недостатками, без
              обеспечения технических характеристик или каких-либо гарантий, при этом его
              использование Пользователем осуществляется исключительно на собственный риск. Весь
              риск, связанный с удовлетворительным качеством и рабочими характеристиками Сервиса,
              лежит на Пользователе. Пеликан софтвер не дает и настоящим отказывается от любых
              явных, подразумеваемых или предусмотренных законом гарантий, включая подразумеваемые
              гарантии состояния, бесперебойного использования, точности данных, пригодности для
              продажи, удовлетворительного качества, пригодности для конкретной цели, не нарушения
              прав третьих лиц, а также гарантий (если таковые имеются), вытекающих из обычной
              практики ведения деловых операций, использования или деловой практики, обычаев
              делового оборота. Пеликан софтвер не гарантирует отсутствие сбоев при использовании
              Пользователем Сервиса; что Сервис будет отвечать требованиям, что работа Сервиса будет
              бесперебойной или безошибочной, или что Сервис будет взаимодействовать или будет
              совместим с любыми другими Сервисами Пеликан софтвер, или что любые ошибки в Сервисе
              будут исправлены. В случае если применимое законодательство РФ не допускает исключение
              или ограничение подразумеваемых гарантий или ограничение применимых предусмотренных
              законом прав потребителей, указанные выше исключения и ограничения применимы в
              максимально разрешенной законом мере. Пеликан софтвер не дает гарантий о применимости
              и допустимости использования Сервиса для Учеников. Это решение принимается
              самостоятельно Учителями на основании проведения должной и полной проверки работы
              Сервиса и их внутренних убеждений.
              {'\n'}
              8.2. Гарантии, указанные в пункте 8.1 настоящего Соглашения, являются существенным
              условием, которое обуславливает интерес Пеликан софтвер для заключения настоящего
              Соглашения. Ни при каких обстоятельствах письменные или устные заявления Пеликан
              софтвер не должны трактоваться в противоречие с указанными гарантиями.
            </p>
            <p>
              <Headline>9. Заключительные положения</Headline>
              9.1. Пеликан софтвер оставляет за собой право незамедлительно без предварительного
              предупреждения Пользователя ограничить или блокировать доступ к Сервису или принимать
              иные меры в отношении Пользователя, нарушившего условия настоящего Соглашения.
              {'\n'}
              9.2. В зависимости от характера материалов или специфики отдельных материалов Сервиса,
              Пеликан софтвер может вводить специальные условия для регистрации и доступа к таким
              материалам и Сервису, в том числе ограничения по возрасту.
              {'\n'}
              9.3. Регистрируясь в Сервисе, Пользователь подтверждает своё безоговорочное согласие
              со всеми положениями Соглашения, а также свое соответствие всем условиям, отраженным в
              формах регистрации и необходимым для успешной регистрации.
              {'\n'}
              9.4. Настоящее Соглашение может быть расторгнуто Пеликан софтвер в одностороннем
              порядке, в случае нарушения Пользователем его условий и положений. В этом случае
              Пользователь обязан незамедлительно прекратить доступ к Сервису и использование
              Интеллектуальной собственности Пеликан софтвер.
              {'\n'}
              9.5. Настоящее соглашение не является договором присоединения или публичной офертой.
              Пользователь вправе предлагать изменения и/или дополнения к нему путем направления их
              на адрес: info@education-team.ru. При этом до согласования таких изменений
              Пользователь должен воздержаться от использования Сервиса. В случае использования
              Сервиса Пользователем после направления предложений об изменении и/или дополнении
              условий настоящего Соглашения, но до их согласования, такие предложения считаются
              отозванными, считается, что Пользователь согласился с условиями настоящего Соглашения.
              {'\n'}
              9.6. Пеликан софтвер сохраняет за собой право по своему усмотрению изменять,
              исправлять, добавлять или удалять части настоящего Соглашения путем размещения
              обновленной версии Соглашения на Сайте. Продолжение использования Сервиса
              Пользователем после внесения изменений и/или дополнений в настоящее Соглашение
              означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.
              {'\n'}
              9.7. Если какое-либо положение настоящего Соглашения будет признано судом
              недействительным, Стороны, несмотря на это, должны приложить усилия для приведения в
              действие намерений Сторон, изложенных в настоящем Соглашении, при этом другие
              положения сохранят юридическую силу в полной мере.
              {'\n'}
              9.8. Споры между Пеликан софтвер и Пользователями разрешаются путем переговоров, а в
              случае невозможности такого урегулирования в судебном порядке по месту нахождения
              Пеликан софтвер в соответствии с законодательством Российской Федерации.
              {'\n'}
              9.9. Отношения сторон по настоящему Соглашению регулируются законодательством
              Российской Федерации.
              {'\n'}
              9.10. Стороны не имеют никаких сопутствующих устных и/или письменных договоренностей.
              Содержание текста настоящего Соглашения полностью соответствует действительному
              волеизъявлению Сторон.
            </p>
            {'\n'}
            Дата публикации 16.03.2021 г.
          </Trans>
        </Text>
      </Content>
    </Container>
  )
}

export default Rules
