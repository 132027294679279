import React from 'react'
import { Flex } from '@chakra-ui/layout'
import { useDisclosure } from '@chakra-ui/hooks'
import styled from '@emotion/styled'
import { User } from 'components/User'
import { MAX_MOBILE_SCREEN_WIDTH } from 'constants/common'

const Container = styled(Flex)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  align-items: center;
  padding: 0 16px;

  @media (max-width: ${MAX_MOBILE_SCREEN_WIDTH}px) {
    position: absolute;
  }
`

const Left = styled.div`
  flex: 1;
`

const Center = styled.div`
  flex: 1;
`

const Right = styled.div`
  justify-contents: flex-end;
`

export function Header() {
  const {
    isOpen: isUserProfileOpen,
    onClose: onUserProfileClose,
    onOpen: onUserProfileOpen,
  } = useDisclosure()

  return (
    <Container zIndex={isUserProfileOpen ? 100 : 10}>
      <Left />
      <Center />
      <Right>
        <User onOpenProfile={onUserProfileOpen} onCloseProfile={onUserProfileClose} />
      </Right>
    </Container>
  )
}
