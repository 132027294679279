import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button as UIKitButton, Input as UIKitInput } from '@daisy-inc/contest-uikit'
import { Title } from './styledComponents'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Button = styled(UIKitButton)`
  display: block;
`

const Input = styled(UIKitInput)`
  display: block;
  margin: 0 0 1.8em 0;
`
const Comment = styled.div`
  opacity: 0.5;
`

const EMAIL_RE = /\S+@\S+\.\S+/

export interface LoginProps {
  onSubmit: ({ email }: { email: string }) => void
}

export function Login(props: LoginProps) {
  const { onSubmit } = props
  const form = useForm<{ email: string }>({ mode: 'onChange' })
  const { t } = useTranslation('authentication')

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <Title>{t('login_title')}</Title>
      <Controller
        control={form.control}
        name='email'
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Input
            {...form.register(name, { pattern: EMAIL_RE, required: true })}
            label={t('email')}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      {form.formState.isValid ? (
        <Button loading={form.formState.isSubmitting} type='submit'>
          {t('login_submit')}
        </Button>
      ) : (
        <Comment>{t('login_hint')}</Comment>
      )}
    </Form>
  )
}
