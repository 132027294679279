import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { delay } from '@daisy-inc/contest-common/utils'
import { CodeInput } from './CodeInput'
import { Title } from './styledComponents'

const Hint = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: black;
  opacity: 0.5;
  margin-top: 28px;
  white-space: break-spaces;
  padding-right: 30px;
`

function formatDuration(duration: number) {
  return `${Math.round(duration / 1000)} сек`
}

export interface CodeVerificationProps {
  onSubmit: ({ code }: { code: string }) => Promise<any>
  codeLength: number
  codeLifetime: number
}

export function CodeVerification(props: CodeVerificationProps) {
  const { onSubmit, codeLength, codeLifetime } = props
  const form = useForm<{ code: string }>()
  const [isInvalidCode, setIsInvalidCode] = useState<true | undefined>()
  const [countdown, setCountdown] = useState<string>(() => formatDuration(codeLifetime))
  const codeInputRef = useRef<CodeInput>(null)
  const startTimeRef = useRef<number>(Date.now())
  const { t } = useTranslation('authentication')

  useEffect(() => {
    codeInputRef?.current?.focus()
    const timerId = setInterval(() => {
      if (startTimeRef.current + codeLifetime <= Date.now()) {
        clearTimeout(timerId)
      }
      setCountdown(formatDuration(startTimeRef.current + codeLifetime - Date.now()))
    }, 1000)
    return () => clearTimeout(timerId)
  }, [])

  const handleChange = useCallback((value) => {
    if (value.length === codeLength) {
      form.handleSubmit(async () => {
        try {
          await onSubmit({ code: value })
        } catch (error: any) {
          setIsInvalidCode(true)
          // Сбрасываем ввод после таймаута
          await delay(1000)
          setIsInvalidCode(undefined)
          form.reset({ code: '' })
        }
      })()
    }
  }, [])

  return (
    <form>
      <Title>{t('code_verification_title')}</Title>
      <Controller
        control={form.control}
        name='code'
        render={({ field: { onChange, value } }) => (
          <CodeInput
            ref={codeInputRef}
            value={value}
            invalid={isInvalidCode}
            length={codeLength}
            onChange={(value) => {
              onChange(value)
              handleChange(value)
            }}
          />
        )}
      />
      <Hint>{t('code_verification_hint', { codeLength, countdown })}</Hint>
    </form>
  )
}
