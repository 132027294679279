import { createStore, createEffect } from 'effector'
import requestApi from 'lib/requestApi'
import { User } from './types'

export const fetchUserFx = createEffect(async () => {
  const response = await requestApi.GET<User>('/api/?entity=user')
  return response.data
})

export const updateUserFx = createEffect(async (profile: { username?: string }) => {
  const response = await requestApi.POST<User>('/api/?entity=user', profile)
  return response.data
})

export const $user = createStore<User | null>(null)

$user.on([fetchUserFx.doneData, updateUserFx.doneData], (_, user) => user)
