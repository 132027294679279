import React from 'react'
import ReactDOM from 'react-dom'
import { logger } from '@daisy-inc/contest-common/utils'
import { App } from './components/App'
import './index.css'
import './i18n'

logger.init(process.env.REACT_APP_SENTRY_DSN)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
