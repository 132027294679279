export interface AppTheme {
  variant: 'normal' | 'simple'
}

export interface AppThemeSetterProps {
  setAppTheme: (theme: AppTheme) => void
}

export const DEFAULT_THEME = {
  variant: 'normal',
} as AppTheme
