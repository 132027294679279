// @ts-nocheck

const LANGUAGES = {
  ENGLISH: 'en',
  RUSSIAN: 'ru',
}

const DEFAULT_NAMESPACE = 'translation'

const NAMESPACES = [DEFAULT_NAMESPACE, 'authentication', 'oficial']

module.exports = {
  LANGUAGES,
  DEFAULT_NAMESPACE,
  NAMESPACES,
}
