import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { useWindowWidth } from '@react-hook/window-size'
import { delay } from '@daisy-inc/contest-common/utils'
import { MAX_MOBILE_SCREEN_WIDTH } from 'constants/common'
import auth from 'lib/auth'
import { CodeVerification } from './CodeVerification'
import { Login } from './Login'
import { LoginError } from './LoginError'

const CODE_LENGTH = 6
const CODE_LIFETIME = 180000

const Container = styled.div`
  position: fixed;
  z-index: 10;
  top: 36px;
  right: 36px;
  border-radius: 32px 0 32px 32px;
  background: white;
  width: 412px;
  height: 376px;
  padding: 48px;
  box-shadow: 0px 2px 8px rgba(29, 11, 78, 0.08), 0px 8px 24px rgba(29, 11, 78, 0.1);
  box-sizing: border-box;

  @media screen and (max-width: ${MAX_MOBILE_SCREEN_WIDTH}px) {
    position: static;
    padding: 40px;
    border-radius: 0;
    width: 100%;
    box-shadow: rgb(29 11 78 / 8%) 0px 2px 8px;
  }
`

export interface AuthenticationProps {
  onAuthenticate: () => void
}

export function Authentication(props: AuthenticationProps) {
  const { onAuthenticate } = props

  const [submittedEmail, setSubmittedEmail] = useState('')
  const [isVerificationCodeRequested, setIsVerificationCodeRequested] = useState(false)
  const [isVerificationCodeFailed, setIsVerificationCodeFailed] = useState(false)

  const handleSubmitEmail = useCallback(async ({ email }) => {
    try {
      await auth.requestAuthenticationCode({ email })
      setSubmittedEmail(email)
      setIsVerificationCodeRequested(true)
      await delay(CODE_LIFETIME)
      setIsVerificationCodeRequested(false)
    } catch (error: any) {
      if (error.response.data.error === 'too_much_code_requests') {
        setSubmittedEmail('')
        setIsVerificationCodeFailed(true)
        await delay(CODE_LIFETIME)
        setIsVerificationCodeFailed(false)
      }
      setIsVerificationCodeRequested(false)
    }
  }, [])
  const handleSubmitCode = useCallback(
    async ({ code }) => {
      try {
        await auth.authenticate({ email: submittedEmail, code })
        onAuthenticate && onAuthenticate()
        return Promise.resolve()
      } catch (error: any) {
        if (error.response.data.error === 'max_tries_exceeded') {
          setSubmittedEmail('')
          setIsVerificationCodeFailed(true)
          await delay(CODE_LIFETIME)
          setIsVerificationCodeFailed(false)
          setIsVerificationCodeRequested(false)
        }
        return Promise.reject(error)
      }
    },
    [onAuthenticate, submittedEmail],
  )

  const content = (
    <Container>
      {isVerificationCodeFailed ? (
        <LoginError />
      ) : !isVerificationCodeRequested ? (
        <Login onSubmit={handleSubmitEmail} />
      ) : (
        <CodeVerification
          onSubmit={handleSubmitCode}
          codeLength={CODE_LENGTH}
          codeLifetime={CODE_LIFETIME}
        />
      )}
    </Container>
  )

  const windowWidth = useWindowWidth({ wait: 0 })

  if (windowWidth <= MAX_MOBILE_SCREEN_WIDTH) {
    // Рендерится в другом корневом элементе, чтобы панель была перед всейстраницей
    return ReactDOM.createPortal(content, document.getElementById('modal-root') as Element)
  }

  return content
}
