import React, { Component } from 'react'
import { every, isEqual, some, findIndex, isEmpty, dropRight } from 'lodash'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { Input as UIKitInput } from '@daisy-inc/contest-uikit'

const Container = styled.div<{ filled?: boolean; invalid?: boolean }>`
  display: flex;
  opacity: ${(p) => p.filled && !p.invalid && 0.6};
`
const errorAnimation = keyframes`
  0% {
    border-bottom-color: rgba(255, 105, 85, 1);
    color: rgba(255, 105, 85, 1);
  }
  100% {
    border-bottom-color: rgba(121, 73, 255, 1);
    color: rgba(255, 105, 85, 0);
  }
`

const Input = styled(UIKitInput)`
  width: 36px;
  font-family: 'Fact Semi Expanded';
  font-weight: 500;

  input {
    font-size: 36px !important;
    font-weight: 700;
    text-align: center;
    ${(p) =>
      p.invalid &&
      css`
        animation: ${errorAnimation} 0.5s linear 0.5s;
      `}
  }

  & + & {
    margin-left: 16px;
  }
`

interface CodeInputProps {
  length: number
  value: string
  invalid?: boolean
  onChange?: (value: string) => void
}

interface CodeInputState {
  value: string[]
}

type UIKitInputHandle = React.ElementRef<typeof UIKitInput>

export class CodeInput extends Component<CodeInputProps, CodeInputState> {
  private inputRefs: UIKitInputHandle[] = []

  state: CodeInputState = {
    value: Array.from(new Array(this.props.length)).fill(''),
  }

  componentDidUpdate(prevProps: CodeInputProps) {
    const { length, value } = this.props
    if (!isEqual(prevProps.value, value)) {
      this.setState({
        value: [...value.split(''), ...Array.from(new Array(length)).fill('')].slice(0, length),
      })

      if (some(prevProps.value) && every(value, (v) => v === '')) {
        this.focus(0)
      }
    }
  }

  refInput(input: UIKitInputHandle, index: number) {
    this.inputRefs[index] = input
  }

  focus(index = 0) {
    if (index < 0 || index >= this.props.length) return
    // @ts-ignore
    this.inputRefs[index]!.focus()
  }

  private setValue(index: number, value: string) {
    const { length, onChange } = this.props
    if (index < 0 || index >= length) return
    const newValue = [
      ...dropRight(this.state.value, length - index),
      ...value
        .toUpperCase()
        .replace(/[^A-Z0-9]/gi, '')
        .split(''),
      ...Array.from(new Array(length)).fill(''),
    ].slice(0, length)
    this.setState({
      value: newValue,
    })
    onChange && onChange(newValue.join(''))
  }

  private handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const firstEmptyInputIndex = findIndex(this.state.value, isEmpty)
    this.setValue(firstEmptyInputIndex, event.clipboardData.getData('Text'))
  }

  render() {
    const { invalid, length } = this.props
    const { value } = this.state
    return (
      <Container filled={findIndex(value, isEmpty) === -1} invalid={invalid}>
        {value.map((val, index) => (
          <Input
            invalid={invalid}
            key={index}
            maxLength={length}
            ref={(input: UIKitInputHandle) => this.refInput(input, index)}
            value={val}
            onChange={(event: any) => {
              if (
                event.nativeEvent.inputType !== 'insertFromPaste' &&
                event.target.value.replace(/[^A-Z0-9]/gi, '')
              ) {
                this.setValue(index, event.target.value)
                this.focus(index + 1)
              } else {
                const firstEmptyInputIndex = findIndex(value, isEmpty)
                this.focus(firstEmptyInputIndex)
              }
            }}
            onPaste={this.handlePaste}
            onKeyDown={(event: React.KeyboardEvent<Element>) => {
              if (!value[length - 1]) {
                if (event.nativeEvent.code === 'Backspace') {
                  const newFirstEmptytIndex = Math.max(findIndex(value, isEmpty) - 1, 0)
                  this.setValue(newFirstEmptytIndex, '')
                  this.focus(newFirstEmptytIndex)
                } else if (
                  event.nativeEvent.code.startsWith('Digit') ||
                  event.nativeEvent.code.startsWith('Key')
                ) {
                  const firstEmptyInputIndex = findIndex(value, isEmpty)
                  this.focus(firstEmptyInputIndex)
                } else {
                  event.preventDefault()
                }
              } else {
                event.preventDefault()
              }
            }}
          />
        ))}
      </Container>
    )
  }
}
