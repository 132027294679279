import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { useStore } from 'effector-react'
import styled from '@emotion/styled'
import { ThemeProvider } from '@emotion/react'
import { Spinner } from '@daisy-inc/contest-uikit'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { Header } from 'components/Header'
import auth from 'lib/auth'
import { fetchUserFx } from 'models/user'
import { Home } from 'pages/Home'
import { NotFound } from 'pages/NotFound'
import { Policy } from 'pages/Policy'
import { Rules } from 'pages/Rules'
import { Oferta } from 'pages/Oferta'
import './styles.module.scss'
import { AppTheme, DEFAULT_THEME } from './theme'

const Challenge = lazy(() => import('pages/Challenge'))

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`

function shouldRenderHeader() {
  return window.location.pathname !== '/' && !window.location.pathname.startsWith('/pages/')
}

export function App() {
  useEffect(() => {
    if (auth.isAuthenticated()) {
      fetchUserFx()
    }
  }, [])

  const isLoading = useStore(fetchUserFx.pending)
  const [theme, setAppTheme] = useState<AppTheme>(DEFAULT_THEME)

  return (
    <ThemeProvider theme={theme}>
      <Page>
        {isLoading ? (
          <Spinner />
        ) : (
          <ErrorBoundary>
            {shouldRenderHeader() && <Header />}
            <Main>
              <Router>
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/pages/policy' exact component={Policy} />
                    <Route path='/pages/rules' exact component={Rules} />
                    <Route path='/pages/oferta' exact component={Oferta} />
                    <Route path='/404' component={NotFound} />
                    <Route
                      path='/verify/:slug/(.*)?'
                      render={(props) => (
                        <Challenge {...props} isVerification setAppTheme={setAppTheme} />
                      )}
                    />
                    <Route
                      path='/:slug/(.*)?'
                      exact
                      render={(props) => <Challenge {...props} setAppTheme={setAppTheme} />}
                    />
                    <Route path='*'>
                      <Redirect to='/404' />
                    </Route>
                  </Switch>
                </Suspense>
              </Router>
            </Main>
          </ErrorBoundary>
        )}
      </Page>
    </ThemeProvider>
  )
}
