import React from 'react'
import { compact, toUpper } from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Box } from '@chakra-ui/layout'
import { chakra } from '@chakra-ui/system'
import { User as UserType } from 'models/user/types'
import { AppTheme } from 'components/App'

const Placeholder = styled(Box)`
  background: white;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  font: 800 22px/48px 'Fact Semi Expanded';
  ${({ theme }: { theme?: AppTheme }) =>
    theme?.variant === 'simple'
      ? css`
          color: 'black';
          box-shadow: 0px 2px 6px rgba(34, 28, 125, 0.13);
        `
      : css`
          color: #7949ff;
          box-shadow: 0px 1px 2px rgba(50, 35, 253, 0.160128),
            0px 2px 6px rgba(50, 35, 253, 0.235281);
        `}
`

export interface AvatarProps {
  user: UserType
}

export const Avatar = chakra((props: AvatarProps) => {
  const { user, ...restProps } = props
  const [firstName, lastName] = user?.username?.split(' ', 2) || ['John', 'Dow']
  const initials = compact([firstName?.charAt(0), lastName?.charAt(0)]).map(toUpper)

  return <Placeholder {...restProps}>{initials}</Placeholder>
})
