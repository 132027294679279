import requestApi from 'lib/requestApi'

const AUTH_TOKEN_KEY = 'authToken'

class Auth {
  constructor() {
    const authToken = this.getAuthToken()

    // Если на клиенте есть сохраненный токен, то используем его в запросах к бэку
    if (authToken) {
      requestApi.setAuthorizationToken(authToken)
    }

    requestApi.registerListener({
      fail: (event: any) => {
        // Если токен плохой разлогиниваем пользователя
        if (event?.response?.status === 401) {
          this.logout()
        }
      },
    })
  }

  requestAuthenticationCode({ email }: { email: string }) {
    return requestApi.POST('/api/?entity=login', { email })
  }

  async authenticate({ email, code }: { email: string; code: string }) {
    try {
      const response = await requestApi.POST<{ code: string }>('/api/?entity=login', {
        email,
        code,
      })
      const authToken = response.data.code

      // Сохраняем токен на клиенте
      localStorage.setItem(AUTH_TOKEN_KEY, authToken)
      // Используем во всех последующих запросах authToken
      requestApi.setAuthorizationToken(authToken)

      return response
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

  logout() {
    localStorage.removeItem(AUTH_TOKEN_KEY)
  }

  isAuthenticated() {
    return !!this.getAuthToken()
  }

  private getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
  }
}

export default new Auth()
