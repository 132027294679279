import React from 'react'
import { Trans } from 'react-i18next'
import { Flex, VStack } from '@chakra-ui/layout'
import styled from '@emotion/styled'

const Container = styled(Flex)`
  justify-content: center;
`

const Content = styled.div`
  box-sizing: border-box;
  min-width: 990px;
  max-width: 1340px;
  padding: 64px 80px 80px;
  flex: 1 1;
  font: 16px/24px TT Norms, sans-serif;
  line-height: 24px;
`

const Text = styled(VStack)`
  white-space: pre-wrap;
  align-items: flex-start;
  width: 100%;
  min-width: 500px;
  max-width: 1000px;
`

const Headline = styled.span`
  display: block;
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 20px;
  font-weight: bold;
`

export function Oferta() {
  return (
    <Container>
      <Content>
        <Text spacing='20px'>
          <Trans i18nKey='oficial:oferta'>
            <h1>Оферта</h1>
            <p>
              Общество с ограниченной ответственностью «Пеликан софтвер» (далее — «Исполнитель»)
              настоящей публичной офертой предлагает любому физическому или юридическому лицу, а
              также индивидуальному предпринимателю (далее — «Заказчик») заключить договор на
              оказание информационно-консультационных услуг, оказываемых посредством сети Интернет
              (далее — «Договор»). В соответствии с пунктом 3 статьи 438 Гражданского кодекса
              Российской Федерации (ГК РФ) в случае принятия изложенных ниже условий и оплаты услуг,
              лицо, производящее акцепт этой оферты, становится Заказчиком. В дальнейшем Исполнитель
              и Заказчик совместно именуются «Стороны». В связи с вышеизложенным, внимательно
              прочитайте текст Договора и, если вы не согласны с каким-либо пунктом Договора,
              Исполнитель предлагает вам отказаться от действий, необходимых для акцепта или
              заключить персональный договор на отдельно обсуждаемых с Исполнителем условиях.
              {'\n'}
              Полным и безоговорочным акцептом настоящей оферты является осуществление Заказчиком
              оплаты предложенных Исполнителем услуг.
            </p>

            <p>
              <Headline>1. Общие положения</Headline>
              1.1. В настоящем Договоре применяются следующие термины и определения:
              {'\n'}
              a. Сайт — любая из автоматизированных информационных систем, доступных в сети Интернет
              по сетевым адресам в следующем домене (включая поддомены): www.pelican.study
              {'\n'}
              b. Заявка — совершение Заказчиком действий, перечисленных на соответствующей странице
              его Личного кабинета, необходимых для оформления отдельного Договора.
              {'\n'}
              c. Личный кабинет — персональный раздел Сайта, к которому Заказчик получает доступ
              после прохождения регистрации и/или авторизации на Сайте. Личный кабинет предназначен
              для хранения персональных данных Заказчика, просмотра статистической информации о
              совершённых Заявках и управления доступными функциональными возможностями Сайта.
              {'\n'}
              d. Курс — дистанционная программа информационно-консультационных услуг, оказываемых
              посредством сети Интернет и представляющая собой совокупность аудиовизуальных
              (вебинары), текстовых, графических и аудио материалов и программ для ЭВМ (программные
              средства для выполнения тестовых заданий).
              {'\n'}
              1.2. В настоящем Договоре могут быть использованы иные термины и определения, не
              указанные в п.1.1. Договора. В этом случае толкование такого термина производится в
              соответствии с текстом Договора. В случае отсутствия однозначного толкования термина
              или определения в тексте Договора и иных документов, образующих договор на условиях
              Договора, следует руководствоваться его толкованием, определённым: в первую очередь —
              законодательством Российской Федерации, и в последующем — обычаями делового оборота и
              научной доктриной.
              {'\n'}
              1.3. Действующая редакция настоящей публичной оферты (далее — «Оферта») находится на
              Сайте в сети Интернет по адресу https://contest.pelican.study/pages/oferta/
            </p>

            <p>
              <Headline>2. Предмет Договора</Headline>
              2.1. Исполнитель обязуется предоставить информационно-консультационные услуги,
              указанных в п.2.2 Договора (далее – «Услуги»), а Заказчик обязуется оплатить Услуги в
              соответствии с условиями Договора.
              {'\n'}
              2.2. Информационно-консультационные услуги, оказываемые Заказчику, заключаются в
              предоставлении Заказчику доступа через сеть Интернет к материалам, разработанным
              Исполнителем, и могут включать в себя вебинары, проводимые Исполнителем, а также
              аудиовизуальные, текстовые, графические, интерактивные, аудио материалы и программы.
              {'\n'}
              2.3. Заключение Заказчиком настоящего Договора осуществляется путём совершения
              следующих действий (акцепт публичной оферты):
              {'\n'}
              2.3.1. Первичная регистрация или авторизация на Сайте Исполнителя;
              {'\n'}
              2.3.2. Оформление заявки на оказание Услуг на Сайте Исполнителя;
              {'\n'}
              2.3.3. Оплата Услуг, оказываемых Исполнителем, в соответствии с тарифами, указанными
              на Сайте Исполнителя посредствам электронного перевода денежных средств с
              использованием сервиса Яндекс.Касса..
              {'\n'}
              2.4. Настоящий Договор считается заключённым и вступает в силу для Сторон с даты
              оплаты Заказчиком Услуг Исполнителя. Датой оплаты считается день зачисления
              соответствующих денежных средств на расчетный счет Исполнителя.
            </p>

            <p>
              <Headline>3. Условия оказания услуг</Headline>
              3.1. Для приобретения Услуги Заказчик должен оставить заявку на Сайте Исполнителя.
              {'\n'}
              3.2. Заказчик оплачивает Услуги по настоящему Договору в размере, указанном в
              соответствующем прайс-листе, размещённом на Сайте Исполнителя.
              {'\n'}
              3.3. Дата и время проведения вебинаров сообщаются Исполнителем Заказчику путём
              размещения информации на Сайте Исполнителя либо при помощи рассылки на почту,
              указанную при регистрации.
              {'\n'}
              3.4. Обязательным условием оказания Исполнителем Услуг является безоговорочное
              принятие и соблюдение Заказчиком применяемых к отношениям Сторон по Договору
              требований и положений, определённых следующими документами: Пользовательское
              соглашение, размещённое и/или доступное в сети Интернет по адресу
              https://contest.pelican.study/pages/rules/ и включающее общие условия регистрации и
              использования Сайта Исполнителя;
              {'\n'}
              Политика конфиденциальности, размещённая и/или доступная в сети Интернет по адресу
              https://contest.pelican.study/pages/policy/ и содержащая правила предоставления и
              использования персональной информации Заказчика.
              {'\n'}
              3.5. Акцептуя условия Оферты, Заказчик даёт согласие в соответствии с действующим
              законодательством на обработку (систематизацию, накопление, хранение, уточнение,
              использование, уничтожение) предоставленных им персональных данных, в целях заключения
              настоящего Договора, а также его последующего исполнения.
              {'\n'}
              3.6. Услуги Исполнителя считаются оказанными надлежащим образом и принятыми
              Заказчиком, если в течение 3 (трех) рабочих дней с момента, когда они полностью
              оказаны, Заказчик не заявил мотивированного возражения на качество и объем таких услуг
              посредством Личного кабинета или путем направления соответствующей претензии по адресу
              электронной почты Исполнителя, указанному на Сайте.
            </p>

            <p>
              <Headline>4. Стоимость услуг и порядок расчётов</Headline>
              4.1. Общая стоимость Услуг Исполнителя определяется как сумма стоимостей всех
              информационно-консультационных услуг, доступ к которым получил Заказчик после оплаты
              согласно п.2.3.3 Договора на соответствующий период времени, оплаченный Заказчиком.
              {'\n'}
              4.2. Стоимость Услуг определяются Исполнителем и указывается на Сайте Исполнителя. В
              зависимости от даты совершения оплаты Услуг стоимость может изменяться. Информация об
              актуальной стоимости указывается на соответствующей странице Сайта. Изменение
              стоимости не влияет на стоимость Услуг для Заказчиков, оплативших такие Услуги в
              полном объеме по прежней цене до момента изменения стоимости Услуг.
              {'\n'}
              4.3. Приобретение Заказчиком доступа к платным Услугам осуществляется на условиях
              предоплаты в размере полной стоимости Услуг. Если для Услуг предусмотрена поэтапная
              оплата, доступ к каждому этапу осуществляется на условиях предоплаты в размере полной
              стоимости данного этапа.
              {'\n'}
              4.3.1. Приобретение Заказчиком доступа к платным Услугам осуществляется путем оплаты
              доступа (подписки) на соответствующий период времени (месяц, квартал, полугодие).
              Истечение подписки при условии отсутствия ее продления влечет за собой прекращение
              доступа Заказчика к Платным услугам.
              {'\n'}
              4.4. Оплата Услуг осуществляется в рублях Российской Федерации.
              {'\n'}
              4.5. Договор считается заключенным, а акцепт совершенным с момента поступления
              денежных средств на счет Исполнителя, а для бесплатных услуг – с момента регистрации
              или авторизации.
              {'\n'}
              4.6. Если для Курса предусмотрена поэтапная оплата, то отсутствие оплаты Заказчиком
              очередного этапа означает его отказ от Договора.
              {'\n'}
              4.7. Услуги Исполнителя считаются полностью оказанными в момент предоставления
              Заказчику доступа к информационным материалам Исполнителя и истечения срока подписки
              на информационные материалы, оплаченные Заказчиком.
              {'\n'}
              4.8. Акцептуя условия Оферты, Заказчик подтверждает свое согласие, что подтверждение
              факта оказания Услуг будет производится Сторонами в исключительно в соответствии с
              положениями п. п. 4.6. – 4.7. Договора, при этом Стороны отдельно оговаривают, что
              составление акта об оказанных услугах не производится.
            </p>

            <p>
              <Headline>5. Права и обязанности сторон</Headline>
              5.1. Исполнитель обязуется:
              {'\n'}
              5.1.1. Предоставить Услуги в согласованный сторонами срок.
              {'\n'}
              5.1.2. Осуществлять консультационную поддержку относительно предоставляемых услуг,
              порядка оформления заявки по электронной почте info@education-team.ru.
              {'\n'}
              5.1.3. Своевременно информировать Заказчика о графике проведения консультаций и
              изменениях в расписании на странице Курса на Сайте и/или с использованием электронной
              почты, телефона, факса, а также через личный кабинет Заказчика
              {'\n'}
              5.2. Исполнитель имеет право:
              {'\n'}
              5.2.1 В любое время по своему усмотрению в отношении Курсов изменять темы отдельных
              консультаций, менять содержание консультаций, количество, наименование и вид
              материалов, входящих в состав Курсов, даты и время проведения соответствующих
              консультаций, и их продолжительность. При этом Исполнитель гарантирует, что подобные
              изменения не приведут к ухудшению качества Курсов. Информация о таких изменениях
              публикуется на Сайте непосредственно до начала проведения соответствующей
              консультации, в отношении которой произошли такие изменения, и/или направляется
              Заказчику по электронной почте;
              {'\n'}
              5.3. Заказчик обязуется:
              {'\n'}
              5.3.1. Самостоятельно и своевременно знакомиться с датой, временем, стоимостью,
              условиями проведения Курса, с изменениями указанных условий, а также с актуальной
              редакцией Договора после акцепта Оферты
              {'\n'}
              5.3.2. Оплатить Услуги Исполнителя в порядке, предусмотренном настоящим Договором;
              {'\n'}
              5.3.3. Предоставить Исполнителю достоверную информацию согласно регистрационной форме
              на Сайте;
              {'\n'}
              5.3.4. Обеспечить безопасность и сохранность пароля для доступа на Сайт и не
              предоставлять его третьим лицам. При утрате, либо компрометации пароля, а также в
              случае незаконного завладения третьими лицами доступа к Личному кабинету, Заказчик
              обязан незамедлительно сообщить об этом Исполнителю. До момента поступления такого
              сообщения все действия, совершенные с использованием Личного кабинета Заказчика будут
              считаться совершенными самим Заказчиком.
              {'\n'}
              5.3.5. Не использовать программное обеспечение и осуществлять действия, направленные
              на нарушение нормального функционирования Сайта и его сервисов или персональных
              страниц пользователей Сайта, не загружать, не хранить, не публиковать, не
              распространять и не предоставлять доступ или иным образом использовать вирусы, трояны
              и другие вредоносные программы; не использовать без специального на то разрешения
              Исполнителя автоматизированные скрипты (программы) для сбора информации на Сайте и
              (или) взаимодействия с Сайтом и его сервисами;
              {'\n'}
              5.4. Исполнитель имеет право:
              {'\n'}
              5.4.1. Прекратить (не начинать) оказание Услуг и вернуть денежные средства Заказчика
              за Услуги в полном объеме, если не наберется необходимая по количеству человек группа.
              Количество человек необходимое для запуска курса зависит от общей цены курса и его
              длительности и определяется Исполнителем отдельно для определенных Услуг.
              {'\n'}
              5.5. Заказчик имеет право:
              {'\n'}
              5.5.1. Требовать от Исполнителя соблюдения условий настоящего Договора.
              {'\n'}
              5.5.2. Требовать от Исполнителя предоставления информации по вопросам, касающимся
              организации и обеспечения надлежащего оказания Услуг.
            </p>

            <p>
              <Headline>6. Ответственность сторон</Headline>
              6.1. В случаях неисполнения или ненадлежащего исполнения своих обязательств по
              Договору, Стороны несут ответственность в соответствии с законодательством Российской
              Федерации с учётом условий настоящего Договора.
              {'\n'}
              6.2. Исполнитель не несёт ответственности в случае ненадлежащего оказания услуги, если
              ненадлежащее исполнение явилось следствием недостоверности, недостаточности или
              несвоевременности предоставленных Заказчиком сведений, а также вследствие других
              нарушений условий настоящего Договора со стороны Заказчика.
              {'\n'}
              6.3. Исполнитель не несёт ответственности за несоответствие предоставленной услуги
              ожиданиям Заказчика и/или за его субъективную оценку, такое несоответствие ожиданиям
              и/или отрицательная субъективная оценка не являются основаниями считать услуги
              оказанными не качественно или не в согласованном объёме.
              {'\n'}
              6.4. Исполнитель освобождается от ответственности за полное или частичное неисполнение
              обязательств, предусмотренных настоящим Договором, если это неисполнение явилось
              следствием обстоятельств непреодолимой силы, возникших после заключения Договора в
              результате событий чрезвычайного характера, которые Стороны не могли ни предвидеть, ни
              предотвратить разумными мерами (форс-мажор).
              {'\n'}
              6.5. Все возникшие в период действия настоящего Договора разногласия решаются путём
              переговоров сторон, а при недостижении соглашения — передаются на рассмотрение
              судебные органы в соответствии с действующим гражданско-процессуальным
              законодательством Российской Федерации.
            </p>

            <p>
              <Headline>
                7. Интеллектуальная собственность и ограничения при пользовании Сайтом
              </Headline>
              7.1. Сайт и Услуги содержат результаты интеллектуальной деятельности, права на которые
              принадлежат Исполнителю.
              {'\n'}
              7.2. Используя Сайт, а также получая консультации в рамках выбранной Услуги, Заказчик
              признает и соглашается с тем, что все содержимое Сайта, все материалы Услуг, структура
              содержимого Сайта или Услуги защищены авторским правом, правом на товарный знак и
              другими правами на результаты интеллектуальной деятельности, и что указанные права
              являются действительными и охраняются во всех формах, на всех носителях и в отношении
              всех технологий, как существующих в настоящее время, так и разработанных или созданных
              впоследствии. Никакие права на любое содержимое Сайта или Услуги, включая, но не
              ограничиваясь, аудиовизуальные произведения, конспекты занятий, изображения,
              литературные произведения, учебные и методические материалы, товарные знаки, не
              переходят к Заказчику в результате пользования Сайтом, акцепта настоящего
              договора-оферты и получения доступа к материалам Услуги.
              {'\n'}
              7.2.1. Копировать и/или распространять какую-либо информацию (включая части и
              компоненты Услуги, тесты, учебные материалы), полученную на Сайте;
              {'\n'}
              7.2.2. Использовать информацию, полученную на Сайте для осуществления коммерческой
              деятельности, извлечения прибыли, либо для использования противоречащим закону
              способом;
              {'\n'}
              7.2.3. Копировать, либо иным способом использовать программную часть Сайта, а также
              его дизайн;
              {'\n'}
              7.2.4. Изменять каким бы то ни было способом программную часть Сайта, совершать
              действия, направленные на изменение функционирования и работоспособности Сайта;
              {'\n'}
              7.3. При цитировании материалов Сайта, включая контент, содержащийся в Услугах,
              Заказчик обязуется указывать ссылку на Сайт.
            </p>

            <p>
              <Headline>8. Прочие условия</Headline>
              8.1. Настоящий Договор вступает в силу с момента оплаты Услуг Исполнителя Заказчиком
              или регистрации (авторизации) для получения бесплатных услуг (акцепт настоящей оферты)
              и действует до полного исполнения обязательств Сторон.
              {'\n'}
              8.2. Акцептуя условия Оферты Заказчик подтверждает, что все условия настоящего
              Договора ему ясны, и он принимает их безусловно и в полном объёме и будет
              руководствоваться ими в ходе получения Услуг.
              {'\n'}
              8.3. Во всём остальном, что не предусмотрено настоящим Договором, стороны будут
              руководствоваться действующим законодательством Российской Федерации.
            </p>
          </Trans>
        </Text>
      </Content>
    </Container>
  )
}

export default Oferta
