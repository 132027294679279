import React from 'react'
import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'

const Container = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
`

const ErrorCode = styled.h1`
  font: 800 92px/100% 'Fact Semi Expanded';
  margin: 0 0 20px 0;
`

export function NotFound() {
  const { t } = useTranslation()

  return (
    <Container>
      <ErrorCode>404</ErrorCode>
      {t('page_not_found')}
    </Container>
  )
}

export default NotFound
