import React from 'react'
import { Box } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'
import { Title } from './styledComponents'

export function LoginError() {
  const { t } = useTranslation('authentication')

  return (
    <>
      <Title>{t('login_error_title')}</Title>
      <Box opacity='0.5'>{t('login_error_hint')}</Box>
    </>
  )
}
