import Axios, { AxiosPromise, Method } from 'axios'
import { isEmpty, isPlainObject, keys, merge } from 'lodash'
import { Observer, Listener } from '@daisy-inc/contest-common/core'

const axios = Axios.create()

axios.interceptors.response.use(undefined, (error) => {
  if (
    error?.response?.status === 502 &&
    error?.response?.data?.errorMessage === 'Connection terminated unexpectedly'
  ) {
    return axios.request(error.config)
  }

  return Promise.reject(error)
})

const DEFAULT_OPTIONS = {
  timeout: 60000,
}

type RequestMethod = <T>(
  urlTemplate: string,
  data?: { [key: string]: any },
  methodOptions?: { [key: string]: any },
) => AxiosPromise<T>

interface RequestApiListener extends Listener {
  fail: (event: any) => void
}

export class RequestApi extends Observer<RequestApiListener> {
  private authorizationToken!: string

  GET: RequestMethod
  POST: RequestMethod
  PUT: RequestMethod
  DELETE: RequestMethod

  constructor() {
    super()

    this.GET = this.makeMethod('GET')
    this.POST = this.makeMethod('POST')
    this.PUT = this.makeMethod('PUT')
    this.DELETE = this.makeMethod('DELETE')
  }

  private makeMethod(method: Method, creatorOptions?: { [key: string]: any }) {
    return async <T = any>(
      urlTemplate: string,
      data?: { [key: string]: any },
      options?: { [key: string]: any },
      // @ts-ignore хотя интерфейс AxiosPromise расширяет Promise, но typescript ожидает, что async-функция вернет именно Promise
    ): AxiosPromise<T> => {
      const requestOptions: { [key: string]: any } = merge(
        DEFAULT_OPTIONS,
        creatorOptions,
        this.authorizationToken && {
          headers: { Authorization: `Token ${this.authorizationToken}` },
        },
        options,
      )
      let url = urlTemplate

      if (data && isPlainObject(data)) {
        for (const tag of url.match(/:\w+/g) || []) {
          const paramName = tag.slice(1)
          let value = data[paramName]
          delete data[paramName]
          if (value === undefined) {
            console.warn(`Warning: calling`, method, `without`, tag)
            value = ``
          }
          if (!requestOptions.raw || !requestOptions.raw[paramName]) {
            value = encodeURIComponent(value)
          }
          url = url.replace(tag, value)
        }

        for (const key of keys(data)) {
          if (data[key] === undefined) {
            delete data[key]
          }
        }

        if (isEmpty(data)) {
          data = undefined
        }
      }

      try {
        const response = await axios({
          method,
          url,
          data,
          ...requestOptions,
        })

        return response
      } catch (error: any) {
        this.fireEvent(error, 'fail')

        return Promise.reject(error)
      }
    }
  }

  setAuthorizationToken(token: string) {
    this.authorizationToken = token
  }
}

const instance = new RequestApi()

export default instance
