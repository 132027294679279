import React from 'react'
import styled from '@emotion/styled'
import { MAX_MOBILE_SCREEN_WIDTH } from 'constants/common'

const TgLink = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: 800 40px/54px 'Fact Semi Expanded';
  color: #7949ff;
  text-decoration: none;

  @media (max-width: ${MAX_MOBILE_SCREEN_WIDTH}px) {
    font-size: 7vw;
  }
`

export function Home() {
  return <TgLink href='https://t.me/PelicanChamps'>t.me/PelicanChamps</TgLink>
}

export default Home
