import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/layout'
import { logger } from '@daisy-inc/contest-common/utils'

const Container = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;
`

export class ErrorBoundary extends Component<any, { error?: Error }> {
  constructor(props: any) {
    super(props)
    this.state = { error: undefined }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error })
    logger.error(error, { extra: errorInfo })
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <Trans i18nKey='error_page'>
            Что-то пошло не так.
            {'\n'}
            Вы видите страницу, которую не должны были увидеть 😔
            {'\n'}
            Наша команда уже оповещена о случившемся.
          </Trans>
        </Container>
      )
    }

    return this.props.children
  }
}
